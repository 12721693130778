import React, { useState } from 'react'
import {
  Card,
  Col,
  Container,
  Alert,
  Row,
  Breadcrumb,
  Button,
} from 'react-bootstrap'
import CopyToClipboardButton from 'components/copy-to-clipboard-button'
import StripeProductTable from 'components/stripe-products-table'
import {
  AdjustProductQuantity,
  AdjustSeatCountCheckBox,
  AdjustSeatCountWarning,
  LinqCouponId,
  LinqOneDiscountInput,
  StripeSelect,
} from 'components/stripe-link-builder-components'
import {
  useAdminStripeAvailablePrices,
  useAdminStripeAvailableProducts,
} from 'hooks/use-admin-stripe'
import { useCreateStripeCheckoutLink } from 'hooks/use-admin-stripe-checkout-links'
import { useGetInternalAdmins } from 'hooks/use-get_internal-admins'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { LINQ_FOR_TEAMS_PRICING_URL } from 'constants/env'
import { LineItem } from 'interfaces/line-item'
import { User } from 'interfaces/user'
import { RootState } from 'index'
import { StripeProduct } from 'interfaces/stripe-product'
import { LINQ_ONE_DISCOUNTS } from 'constants/linq-one-discount'

const LINQ_ONE_PRODUCT_ID = 'prod_QmwEcZEeTODmrW'

const TeamsLinkBuilderPage = () => {
  const currentUser = useSelector<RootState, User | null>(
    (state) => state.user.currentUser
  )
  const [couponId, setCouponId] = useState<string | undefined>('')
  const [linqOneDiscount, setLinqOneDiscount] = useState<
    string | number | undefined
  >(undefined)
  const [saleRepId, setSaleRepId] = useState<string | number | undefined>(
    currentUser?.id
  )
  const [productId, setProductId] = useState<string | number | undefined>('')
  const [productPriceId, setProductPriceId] = useState<
    string | number | undefined
  >('')
  const [productQuantity, setProductQuantity] = useState(0)
  const [allowQuantityAdjusting, setAllowQuantityAdjusting] = useState(false)
  const [addedProductsList, setAddedProductsList] = useState<
    Partial<StripeProduct>[]
  >([])
  const [savedLineItems, setSavedLineItems] = useState<Partial<LineItem>[]>([])
  const [checkoutLink, setCheckoutLink] = useState<string | undefined>(
    undefined
  )

  const {
    data: availableProducts,
    isLoading: isProductsLoading,
    refetch: refectchAvailableProducts,
    isFetching: productsIsFetching,
  } = useAdminStripeAvailableProducts()
  const {
    data: productPrices,
    isLoading: isProductPricesLoading,
    refetch: refetchProductPrices,
    isFetching: productPricesIsFetching,
  } = useAdminStripeAvailablePrices(productId || '')

  const { mutate: stripeCheckoutLink, isLoading: stripeCheckoutLinkLoading } =
    useCreateStripeCheckoutLink()

  const { data: internalAdmins, isFetching: isInternalAdminFetchiing } =
    useGetInternalAdmins()

  const handleNewCouponClick = () => {
    window.open('https://dashboard.stripe.com/coupons', '_blank')
  }

  const handleAddProducts = (
    productId: number | string | undefined,
    productPriceId: number | string | undefined,
    productQuantity: number
  ) => {
    if (productId && productPriceId) {
      const selectedProduct = availableProducts?.find(
        (product) => product.id === productId
      )
      const selectedPrice = productPrices?.find(
        (price) => price.id === productPriceId
      )

      let changeWithOrgSeatCount
      if (selectedPrice?.metadata?.change_with_org_seat_count) {
        changeWithOrgSeatCount =
          selectedPrice?.metadata?.change_with_org_seat_count
      } else if (selectedProduct?.metadata?.change_with_org_seat_count) {
        changeWithOrgSeatCount =
          selectedProduct?.metadata?.change_with_org_seat_count
      }

      setAddedProductsList([
        ...addedProductsList,
        {
          name: selectedProduct?.name,
          id: selectedProduct?.id,
          price: selectedPrice?.unit_amount
            ? Number(selectedPrice?.unit_amount / 100).toFixed(2)
            : undefined,
          description: selectedPrice?.metadata?.internal_description,
          metadata: {
            change_with_org_seat_count: changeWithOrgSeatCount,
          },
          quantity: productQuantity,
          isAdjustableQuantity: allowQuantityAdjusting,
        },
      ])
      setSavedLineItems([
        ...savedLineItems,
        {
          price: productPriceId,
          quantity: productQuantity,
          adjustable_quantity: {
            enabled: allowQuantityAdjusting && productQuantity < 999,
            minimum: allowQuantityAdjusting && productQuantity < 999 ? 1 : null,
            maximum:
              allowQuantityAdjusting && productQuantity < 999 ? 999 : null,
          },
        },
      ])
    } else {
      toast('Both Product and Price must be selected', { icon: '❌' })
    }
  }

  const handleRemoveProduct = (index: number) => {
    const updatedProductList = addedProductsList.filter(
      (_, idx) => idx !== index
    )
    setAddedProductsList(updatedProductList)
    const updateSavedLineItems = savedLineItems.filter(
      (_, idx) => idx !== index
    )
    setSavedLineItems(updateSavedLineItems)
  }

  const handleSaveLineItems = () => {
    const hasLinqOneProduct = addedProductsList?.some(
      (product) => product.id === LINQ_ONE_PRODUCT_ID
    )
    const hasLinqForTeamsProduct = addedProductsList?.some(
      (product) =>
        product.id === process.env.REACT_APP_STRIPE_LINQ_FOR_TEAM_PRODUCT_ID
    )

    if (!hasLinqOneProduct && !hasLinqForTeamsProduct) {
      toast('HEY, YOU MUST ADD LINQ FOR TEAMS SUBSCRIPTION', { icon: `🚨` })
      return
    }
    const linqOneCouponId = LINQ_ONE_DISCOUNTS.find(
      (coupon) => coupon.percent_off === linqOneDiscount
    )?.id
    const coupon = hasLinqOneProduct ? linqOneCouponId : couponId

    stripeCheckoutLink(
      {
        lineItems: savedLineItems,
        couponId: coupon,
        saleRepId: saleRepId,
      },
      {
        onSuccess: (data) => {
          setCheckoutLink(data.link)
        },
      }
    )
  }

  const handleLFTPricingPageClicked = () => {
    if (LINQ_FOR_TEAMS_PRICING_URL) {
      window.open(LINQ_FOR_TEAMS_PRICING_URL, '_blank')
    } else {
      toast('Pricing url missing... Contact Engineering', { icon: `🤷‍♂️` })
    }
  }

  const clearProductFormFields = () => {
    setProductId('')
    setProductPriceId('')
    setProductQuantity(0)
    setAllowQuantityAdjusting(false)
    toast('Fields cleared', { icon: `🚀` })
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active href="/teams-purchase-link">
          Teams Purchase Link Builder
        </Breadcrumb.Item>
      </Breadcrumb>
      <Card className="mt-4 mb-4 p-2 text-white bg-dark">
        <Card.Body>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <h4 className="card-title">
                Linq for Teams Purchase Link Builder
              </h4>
              <div className="card-subtitle m-b-2 text-muted">
                Use the form below to build a link to send to potential Linq for
                Teams customers.
              </div>
            </div>
            <div>
              <Button variant="primary" onClick={handleLFTPricingPageClicked}>
                LFT Pricing Page <ion-icon name="open-outline" />
              </Button>
            </div>
          </div>
          <Row>
            <Col lg={3}>
              <StripeSelect
                id="products"
                label="products"
                labelDescription="Linq Products"
                nullValue="Select a product..."
                value={productId}
                setState={setProductId}
                data={availableProducts}
                refetch={refectchAvailableProducts}
                showRefetchButton={!isProductsLoading}
                isFetching={productsIsFetching}
              />
              <StripeSelect
                id="productPrice"
                label="productPrice"
                labelDescription="Prices"
                nullValue="Select a price..."
                value={productPriceId}
                setState={setProductPriceId}
                data={productPrices}
                refetch={refetchProductPrices}
                showRefetchButton={Boolean(
                  productId && !isProductPricesLoading
                )}
                isFetching={productPricesIsFetching}
              />
              {(productId === LINQ_ONE_PRODUCT_ID || linqOneDiscount) && (
                <LinqOneDiscountInput
                  linqOneDiscount={linqOneDiscount}
                  setLinqOneDiscount={setLinqOneDiscount}
                />
              )}
              <AdjustProductQuantity
                productQuantity={productQuantity}
                setProductQuantity={setProductQuantity}
                allowQuantityAdjusting={allowQuantityAdjusting}
                setAllowQuantityAdjusting={setAllowQuantityAdjusting}
              />
              <AdjustSeatCountCheckBox
                productQuantity={productQuantity}
                allowQuantityAdjusting={allowQuantityAdjusting}
                setAllowQuantityAdjusting={setAllowQuantityAdjusting}
              />
              <AdjustSeatCountWarning
                productQuantity={productQuantity}
                allowQuantityAdjusting={allowQuantityAdjusting}
              />

              <div style={{ display: 'flex' }}>
                <Button
                  style={{ marginRight: '0.5rem', flexGrow: 1 }}
                  className="mt-2"
                  onClick={() =>
                    handleAddProducts(
                      productId,
                      productPriceId,
                      productQuantity
                    )
                  }
                  variant="success"
                >
                  + Add Product
                </Button>
                <Button className="mt-2" onClick={clearProductFormFields}>
                  Clear Fields
                </Button>
              </div>
            </Col>
            <Col lg={9}>
              <div className="form-floating mt-4 mb-4">
                <h4>Added Products</h4>
                <Alert variant="secondary">
                  <StripeProductTable
                    addedProductsList={addedProductsList}
                    handleRemoveProduct={handleRemoveProduct}
                  />
                </Alert>
              </div>
            </Col>
          </Row>
          <hr />
          {productId !== LINQ_ONE_PRODUCT_ID && !linqOneDiscount && (
            <div className='d-flex flex-row align-items-center ms-2"'>
              <LinqCouponId couponId={couponId} setCouponId={setCouponId} />
              <Button onClick={handleNewCouponClick} className="m-4">
                Stripe Coupons <ion-icon name="open-outline" />
              </Button>
            </div>
          )}
          <StripeSelect
            id="saleReps"
            label="saleReps"
            labelDescription="Sale Representative"
            nullValue="Select a sale rep..."
            isFetching={isInternalAdminFetchiing}
            value={saleRepId}
            setState={setSaleRepId}
            data={internalAdmins as any}
          />
          <Button
            className="mt-2 mb-2"
            onClick={handleSaveLineItems}
            variant="success"
            size="lg"
          >
            Generate Link
          </Button>
          <Alert variant="secondary">
            {stripeCheckoutLinkLoading && <div>Loading...</div>}

            {checkoutLink && (
              <>
                <div className="mb-1">{checkoutLink}</div>
                <CopyToClipboardButton
                  copyText="Copy Link"
                  copiedText="Link Copied to Clipboard"
                  stringToCopy={checkoutLink}
                />
              </>
            )}
          </Alert>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default TeamsLinkBuilderPage
