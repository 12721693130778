import { useMutation } from 'react-query'
import { BASE_API_URL, WEB_APP_URL } from 'constants/env'
import { API_METHODS } from 'constants/api-methods'
import { apiHeaders } from 'utils/api-headers'
import { store } from 'index'
import { handleErrorsAndJSON } from 'utils/handle-errors'
import { LineItem } from 'interfaces/line-item'

type Data = {
  stripe_checkout_link: {
    link: string
  }
}

const fetchCreateStripeCheckoutLink = async (
  lineItems: Partial<LineItem>[],
  couponId: string | number | undefined,
  saleRepId: string | number | undefined
) => {
  const requestUrl = `${BASE_API_URL}/admin/api/v1/stripe_checkout_links`
  let response = await fetch(requestUrl, {
    method: API_METHODS.POST,
    headers: apiHeaders(store.getState()),
    body: JSON.stringify({
      line_items: lineItems,
      coupon_id: couponId,
      sale_rep_id: saleRepId,
      stripe_success_url: `${WEB_APP_URL}/teams/stripe-success/?session_id={CHECKOUT_SESSION_ID}`,
      stripe_cancel_url: 'https://buy.linqapp.com/pages/teams',
    }),
  })
  response = await handleErrorsAndJSON(response)
  const json: Data = await response.json()
  return json.stripe_checkout_link
}

export const useCreateStripeCheckoutLink = () => {
  return useMutation(
    ({
      lineItems,
      couponId,
      saleRepId,
    }: {
      lineItems: Partial<LineItem>[]
      couponId: string | number | undefined
      saleRepId: string | number | undefined
    }) => fetchCreateStripeCheckoutLink(lineItems, couponId, saleRepId)
  )
}
